<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
         <card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('elearning_tim.evaluation') }}
              <slot v-if="$route.query.id">
                {{ $t('globalTrans.update') }}
              </slot>
              <slot v-else>
                {{ $t('globalTrans.entry') }}
              </slot>
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="evaluation" :class="'btn btn-success text-light'">{{ $t('elearning_tim.evaluation') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Exam Type" vid="exam_type" rules="required">
                            <div
                                slot-scope="{ valid, errors }"
                                class="row"
                            >
                              <label for="" class="col-md-4">{{ $t('elearning_tim.exam_type') }}</label>
                              <div class="col">
                                <b-form-radio-group
                                    v-model="formData.exam_type"
                                    :options="examTypeList"
                                    class="mb-3"
                                ></b-form-radio-group>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </div>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Exam Date"  vid="exam_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="exam_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_tim.exam_date') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  class="fromDate"
                                  id="exam_date"
                                  v-model="formData.exam_date"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :class="errors[0] ? 'is-invalid': ''"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                            <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="circular_memo_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                                </template>
                                 <b-form-select
                                  plain
                                  v-model="formData.circular_memo_no"
                                  :options="circularList"
                                  id="circular_memo_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                                <div class="invalid-feedback d-block" v-if="circularErrorMsg">
                                  {{ circularErrorMsg }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Course Name"  vid="course_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="course_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_tim.course_name')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.course_id"
                                  :options="courseList"
                                  id="course_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{ $t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <!-- <b-col lg="6" sm="6">
                          <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="circular_memo_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="circular_memo_no"
                                  v-model="formData.circular_memo_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col> -->
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.fiscal_year_id"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
                          <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.office_type_id"
                                  :options="officeTypeList"
                                  id="office_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.office_id"
                                  :options="officeList"
                                  id="office_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.training_type_id"
                                  :options="trainingTypeList"
                                  id="training_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_category_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.training_category_id"
                                  :options="trainingCategoryList"
                                  id="training_category_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_title_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.training_title_id"
                                  :options="trainingTitleList"
                                  id="training_title_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Exam Time Start"  vid="exam_time_start" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="exam_time_start"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_tim.exam_time_start') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  type="time"
                                  id="exam_time_start"
                                  v-model="formData.exam_time_start"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :placeholder="$t('elearning_tim.exam_time_start')"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Exam End Time"  vid="exam_time_end" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="exam_time_end"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_tim.exam_time_end')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  type="time"
                                  id="exam_time_end"
                                  v-model="formData.exam_time_end"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :placeholder="$t('elearning_tim.exam_time_end')"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row v-for="(evaluationQuestion, questionIndex) in formData.evaluation_questions" :key="questionIndex">
                        <div class="col-md-12">
                          <fieldset class="p-2 w-100">
                            <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tim.question')}} {{ $n(questionIndex+1) }}</legend>
                            <div class="px-3">
                              <b-row>
                                <b-col lg="6" sm="6">
                                  <ValidationProvider name="Question Type" :vid="'question_type' + (questionIndex+1)" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label-for="'question_type' + (questionIndex+1)"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('elearning_tim.question_type') }} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                          plain
                                          v-model="evaluationQuestion.question_type"
                                          :options="questionTypeList"
                                          :id="'question_type' + (questionIndex+1)"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                        <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col lg="6" sm="6">
                                  <ValidationProvider name="Question"  :vid="'question_' + (questionIndex+1)" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label-for="'question_' + (questionIndex+1)"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('elearning_tim.question_en') }} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                          :id="'question_' + (questionIndex+1)"
                                          v-model="evaluationQuestion.question"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :placeholder="$t('elearning_tim.question_en')"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="6">
                                  <ValidationProvider name="Question Bn"  :vid="'question_bn_' + (questionIndex+1)" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label-for="'question_bn_' + (questionIndex+1)"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('elearning_tim.question_bn') }} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                          :id="'question_bn_' + (questionIndex+1)"
                                          v-model="evaluationQuestion.question_bn"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :placeholder="$t('elearning_tim.question_bn')"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col lg="6" sm="6">
                                  <ValidationProvider name="Description"  :vid="'description_' + (questionIndex+1)" rules="">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label-for="'description_' + (questionIndex+1)"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('elearning_tim.description_en') }}
                                      </template>
                                      <b-form-textarea
                                          rows="2"
                                          :id="'description_' + (questionIndex+1)"
                                          v-model="evaluationQuestion.description"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :placeholder="$t('elearning_tim.description_en')"
                                      ></b-form-textarea>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="6">
                                  <ValidationProvider name="Description Bn"  :vid="'description_bn_' + (questionIndex+1)" rules="">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label-for="'description_bn_' + (questionIndex+1)"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{$t('elearning_tim.description_bn')}}
                                      </template>
                                      <b-form-textarea
                                          rows="2"
                                          :id="'description_bn_' + (questionIndex+1)"
                                          v-model="evaluationQuestion.description_bn"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :placeholder="$t('elearning_tim.description_bn')"
                                      ></b-form-textarea>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col lg="6" sm="6">
                                  <ValidationProvider name="Marks"  :vid="'marks_' + (questionIndex+1)" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label-for="'marks_' + (questionIndex+1)"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('elearning_tim.marks') }} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                          type="number"
                                          :id="'marks_' + (questionIndex+1)"
                                          v-model="evaluationQuestion.marks"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :placeholder="$t('elearning_tim.marks')"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="6">
                                  <ValidationProvider name="Attachment" :vid="'attachment_'+questionIndex">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label-for="'attachment_'+questionIndex"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('globalTrans.attachment') }}
                                      </template>
                                      <b-form-file
                                          :id="'attachment_'+questionIndex"
                                          :ref="'attachment_'+questionIndex"
                                          v-model="evaluationQuestion.attachment"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-file>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                      <a v-if="id && evaluationQuestion.attachment" class="btn btn-sm btn-success mt-2" target='_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/' + evaluationQuestion.attachment" download><i class="fas fa-download"></i> {{$t('globalTrans.view_download')}}</a>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col lg="12">
                                  <table class="table table-bordered table-striped" v-if="evaluationQuestion.question_type === 1">
                                    <thead class="thead">
                                    <tr>
                                      <th>{{ $t('globalTrans.sl_no') }}</th>
                                      <th>{{ $t('elearning_tim.is_correct') }}</th>
                                      <th>{{ $t('elearning_tim.option_en') }}</th>
                                      <th>{{ $t('elearning_tim.option_bn') }}</th>
                                      <th>{{ $t('globalTrans.attachment') }}</th>
                                    </tr>
                                    </thead>
                                    <tr v-for="(evaluationOption, optionIndex) in evaluationQuestion.evaluation_options" :key="(questionIndex+1)+(optionIndex+1)">
                                      <td>{{ $n(optionIndex + 1) }}</td>
                                      <td class="text-center align-middle">
                                        <b-form-checkbox
                                            :id="'is_correct_' + (questionIndex + 1) + '_' + (optionIndex + 1)"
                                            v-model="evaluationOption.is_correct"
                                            :name="'is_correct_' + (questionIndex + 1) + '_' + (optionIndex + 1)"
                                            value="1"
                                            unchecked-value="0"
                                        >
                                        </b-form-checkbox>
                                        <!--                                  <small v-if="attachments_errors && attachments_errors['course_attachments.' + index + '.name']" class="text-danger">-->
                                        <!--                                    {{ attachments_errors['course_attachments.'+index+'.name'] }}-->
                                        <!--                                  </small>-->
                                      </td>
                                      <td>
                                        <ValidationProvider name="Option En"  :vid="'option_en_' + (questionIndex+1) + '-' + (optionIndex + 1)" rules="">
                                          <div
                                              slot-scope="{ valid, errors }"
                                          >
                                            <b-form-input
                                                :id="'option_en_' + (questionIndex+1) + '-' + (optionIndex + 1)"
                                                v-model="evaluationOption.option_en"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :placeholder="$t('elearning_tim.option_en')"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </div>
                                        </ValidationProvider>
                                        <!--                                  <small v-if="attachments_errors && attachments_errors['course_attachments.' + index + '.attachment']" class="text-danger">-->
                                        <!--                                    {{ attachments_errors['course_attachments.'+index+'.attachment'] }}-->
                                        <!--                                  </small>-->
                                        <!--                                  <b v-if="course_attachment_file.pre_attachment">-->
                                        <!--                                    (<a target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/' + course_attachment_file.pre_attachment" download>{{$t('globalTrans.view_download')}}</a> )-->
                                        <!--                                  </b>-->
                                      </td>
                                      <td>
                                        <ValidationProvider name="Option Bn"  :vid="'option_bn_' + (questionIndex+1) + '-' + (optionIndex + 1)" rules="">
                                          <div
                                              slot-scope="{ valid, errors }"
                                          >
                                            <b-form-input
                                                :id="'option_bn_' + (questionIndex+1) + '-' + (optionIndex + 1)"
                                                v-model="evaluationOption.option_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :placeholder="$t('elearning_tim.option_bn')"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </div>
                                        </ValidationProvider>
                                      </td>
                                      <td>
                                        <ValidationProvider name="Attachment" :vid="'option_attachment_'+(questionIndex+1)">
                                          <div
                                              slot-scope="{ valid, errors }"
                                          >
                                            <b-form-file
                                                :id="'option_attachment_'+(questionIndex+1) + '_' + (optionIndex + 1)"
                                                :ref="'option_attachment_'+(questionIndex+1) + '_' + (optionIndex + 1)"
                                                v-model="evaluationOption.option_attachment"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-file>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                            <a v-if="id && evaluationOption.option_attachment" class="btn btn-sm btn-success mt-2" target='_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/' + evaluationOption.option_attachment" download><i class="fas fa-download"></i> {{$t('globalTrans.view_download')}}</a>
                                          </div>
                                        </ValidationProvider>
                                      </td>
                                    </tr>
                                  </table>
                                  <div class="mt-3">
                                    <button @click="addItem" class="btn btn-sm btn-primary mr-2" type="button"
                                    ><i class="fas fa-plus-circle m-0"></i> {{$t('globalTrans.add_more')}}</button>
                                    <button @click="remove(questionIndex)" class="btn btn-sm btn-danger" type="button" v-if="formData.evaluation_questions.length > 1"><i class="fas fa-window-close m-0"></i> {{ $t('globalTrans.delete') }}</button>
                                  </div>
                                </b-col>
                              </b-row>
                            </div>

                          </fieldset>
                        </div>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { evaluationStore, evaluationUpdate, evaluationShow, circularPublicationList } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.getCircularList()
    if (this.$route.query.id) {
      this.getFormData()
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      id: this.$route.query.id,
      loading: false,
      circularLoading: false,
      formData: {
        id: '',
        exam_type: 1,
        exam_date: '',
        course_id: 0,
        circular_memo_no: 0,
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        training_category_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        status: 1,
        exam_time_start: '',
        exam_time_end: '',
        evaluation_questions: [
          {
            question_type: 1,
            question: '',
            question_bn: '',
            description: '',
            description_bn: '',
            attachment: null,
            marks: '',
            evaluation_options: [
              {
                option_en: '',
                option_bn: '',
                option_attachment: null,
                is_correct: 0
              },
              {
                option_en: '',
                option_bn: '',
                option_attachment: null,
                is_correct: 0
              },
              {
                option_en: '',
                option_bn: '',
                option_attachment: null,
                is_correct: 0
              },
              {
                option_en: '',
                option_bn: '',
                option_attachment: null,
                is_correct: 0
              }
            ]
          }
        ]
      },
      officeTypeList: [],
      officeList: [],
      circularList: [],
      courseList: [],
      trainingCategoryList: [],
      attachments_errors: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    saveBtnName: function () {
      return this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save')
    },
    examTypeList: function () {
      return [
        { text: this.currentLocale === 'en' ? 'Pre Exam' : 'প্রাক পরীক্ষা', value: 1 },
        { text: this.currentLocale === 'en' ? 'Post Exam' : 'পরবর্তী পরীক্ষা', value: 2 }
      ]
    },
    questionTypeList: function () {
      return [
        { text: this.currentLocale === 'en' ? 'MCQ' : 'এমসিকিউ', value: 1 },
        { text: this.currentLocale === 'en' ? 'Written' : 'লিখিত', value: 2 }
      ]
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    'formData.course_id': function (newValue) {
      this.getCourseInfo(newValue)
    },
    'formData.circular_memo_no': function (newValue) {
      this.courseList = this.$store.state.TrainingElearning.commonObj.courseList.filter(item => item.circular_memo_no === newValue)
      // this.getCourseInfo(newValue)
    },
    'formData.org_id': function (newValue) {
      this.officeTypeList = this.getOfficeTypeList(newValue)
    },
    'formData.office_type_id': function (newValue) {
      this.officeList = this.getOfficeList(newValue)
    },
    'formData.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'formData.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    }
  },
  methods: {
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
        org_id: this.$store.state.dataFilters.orgId
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'tpm_course_management', serchData)
      if (!result.success) {
      } else {
         const listObject = result.data
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          }
        })
        this.circularList = tmpList
      }
      this.circularLoading = false
    },
    async getFormData () {
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, evaluationShow + '/' + this.$route.query.id)
      if (!result.success) {
        this.formData = []
      } else {
        this.formData = result.data
      }
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
      const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
      if (typeId) {
        return trainingCategoryList.filter(item => item.training_type_id === typeId)
      }
      return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    async getCourseInfo (courseId) {
      this.loading = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, 'tpm/course-management/show/' + courseId)
      if (!result.success) {
        this.formData.circular_memo_no = 0
        this.formData.fiscal_year_id = 0
        this.formData.org_id = 0
        this.formData.office_id = 0
        this.formData.office_type_id = 0
        this.formData.training_title_id = 0
        this.formData.training_type_id = 0
        this.formData.training_category_id = 0
      } else {
        const trData = result.data
        // this.formData.circular_memo_no = trData.circular_memo_no
        this.formData.fiscal_year_id = trData.fiscal_year_id
        this.formData.org_id = trData.org_id
        this.formData.office_id = trData.office_id
        this.formData.office_type_id = trData.office_type_id
        this.formData.training_title_id = trData.training_title_id
        this.formData.training_type_id = trData.training_type_id
        this.formData.training_category_id = trData.training_category_id
      }
      this.loading = false
    },
    async createData () {
      this.loading = true
      let result = null
      var formData = new FormData()
      Object.keys(this.formData).map(key => {
        if (key !== 'evaluation_questions') {
          formData.append(key, this.formData[key])
        } else {
          this.formData.evaluation_questions.filter((evaluationQuestion, questionIndex) => {
            if (evaluationQuestion.id) {
              formData.append('evaluation_questions[' + questionIndex + '][id]', evaluationQuestion.id)
            }
            formData.append('evaluation_questions[' + questionIndex + '][question_type]', evaluationQuestion.question_type)
            formData.append('evaluation_questions[' + questionIndex + '][question]', evaluationQuestion.question)
            formData.append('evaluation_questions[' + questionIndex + '][question_bn]', evaluationQuestion.question_bn)
            formData.append('evaluation_questions[' + questionIndex + '][description]', evaluationQuestion.description)
            formData.append('evaluation_questions[' + questionIndex + '][description_bn]', evaluationQuestion.description_bn)
            if (evaluationQuestion.attachment) {
              formData.append('evaluation_questions[' + questionIndex + '][attachment]', evaluationQuestion.attachment)
            }
            formData.append('evaluation_questions[' + questionIndex + '][marks]', evaluationQuestion.marks)
            evaluationQuestion.evaluation_options.filter((evaluationOption, optionIndex) => {
              if (evaluationOption.id) {
                formData.append('evaluation_questions[' + questionIndex + '][evaluation_options][' + optionIndex + '][id]', evaluationOption.id)
              }
              formData.append('evaluation_questions[' + questionIndex + '][evaluation_options][' + optionIndex + '][option_en]', evaluationOption.option_en)
              formData.append('evaluation_questions[' + questionIndex + '][evaluation_options][' + optionIndex + '][option_bn]', evaluationOption.option_bn)
              if (evaluationOption.option_attachment) {
                formData.append('evaluation_questions[' + questionIndex + '][evaluation_options][' + optionIndex + '][option_attachment]', evaluationOption.option_attachment)
              }
              formData.append('evaluation_questions[' + questionIndex + '][evaluation_options][' + optionIndex + '][is_correct]', evaluationOption.is_correct)
            })
          })
        }
      })
      if (this.formData.id) {
        formData.append('_method', 'PUT')
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${evaluationUpdate}/${this.formData.id}`, formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, evaluationStore, formData)
      }
      this.loading = false
      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$router.push('/training-e-learning-service/tim/evaluation')
      } else {
        this.attachments_errors = result.errors
        this.$refs.form.setErrors(result.errors)
      }
    },
    addItem () {
      const obj = {
        question_type: 1,
        question: '',
        question_bn: '',
        description: '',
        description_bn: '',
        attachment: null,
        marks: '',
        evaluation_options: [
          {
            option_en: '',
            option_bn: '',
            option_attachment: null,
            is_correct: 0
          },
          {
            option_en: '',
            option_bn: '',
            option_attachment: null,
            is_correct: 0
          },
          {
            option_en: '',
            option_bn: '',
            option_attachment: null,
            is_correct: 0
          },
          {
            option_en: '',
            option_bn: '',
            option_attachment: null,
            is_correct: 0
          }
        ]
      }
      const key1 = parseInt(this.formData.evaluation_questions.length - 1)
      const item = this.formData.evaluation_questions[key1]
      let isEmpty = false
      // check whether form fields are filled
      Object.keys(item).map(key => {
        if (['question_type', 'question', 'question_bn', 'marks'].includes(key) && (item[key] === '' || item[key] === 0)) {
          isEmpty = true
        }
        if (item.question_type === 1) {
          isEmpty = true
          item.evaluation_options.filter(evaluationOption => {
            if ((evaluationOption.option_en && evaluationOption.option_bn) || (evaluationOption.option_attachment)) {
              isEmpty = false
            }
          })
        }
      })
      if (isEmpty === false) {
        this.formData.evaluation_questions.push(obj)
      }
    },
    remove (key) {
      this.formData.evaluation_questions.splice(key, 1)
    }
  }
}
</script>
